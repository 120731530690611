import React, { useEffect, useState } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-dark.png"

const Partner = ({ data }) => {
  const [selectedPartner, setSelectedPartner] = useState(null)

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/partner")
    }
  }, [])

  useEffect(() => {
    if (selectedPartner) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "visible"
  }, [selectedPartner])

  return (
    <React.Fragment>
      <PageTitle title={"Audit-Partner"} />
      <Layout>
        <div className="auditpartner-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Partner</a>
              </li>
            </ul>
          </div>
          <div className="audit-partner">
            <div className="audit-partner-top">
              <h1>Partner</h1>
              <div className="horizontal-line"></div>
              <div
                className="audit-partner-text"
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsPartner.pageDescription,
                }}
              ></div>
            </div>
            <div className="audit-partner-bottom">
              {data.datoCmsPartner.partners.map((partner, index) => (
                <div className="audit-partner-bottom-partner" key={index}>
                  <div className="audit-partner-title-wrapper">
                    <h2>{partner.partnerType}</h2>
                  </div>
                  <div className="audit-partner-bottom-partner-images">
                    {partner.partners.map((partnerDetails, index2) => (
                      <div key={`${index}-${index2}`}>
                        <img
                          src={partnerDetails.logo.url}
                          alt={partnerDetails.logo.alt}
                          title={partnerDetails.logo.alt}
                          onClick={() => setSelectedPartner(partnerDetails)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {selectedPartner && (
          <div
            className="partner-modal__overflow"
            onClick={() => setSelectedPartner(null)}
          >
            <div className="partner-modal">
              <div className="partner-modal-content">
                <div className="partner-modal-header">
                  <svg
                    viewBox="0 0 44 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setSelectedPartner(null)}
                  >
                    <path
                      d="M1.15254 42.1643L42.1647 1.15207M2.2132 0.798517L43.2254 41.8107"
                      stroke="#0B67F0"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
                <div className="partner-modal-image">
                  <img
                    src={selectedPartner.logo.url}
                    alt={selectedPartner.logo.alt}
                    title={selectedPartner.logo.alt}
                  />
                </div>
                <div className="partner-modal-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedPartner.description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </React.Fragment>
  )
}

export default Partner

export const query = graphql`
  {
    datoCmsPartner(locale: { eq: "de-DE" }) {
      pageDescription
      partners {
        partnerType
        partners {
          description
          logo {
            url
            alt
          }
        }
      }
    }
  }
`
